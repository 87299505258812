import AddressService from "~/parts/core/common/address/address.referential.service";
import { type Address } from "~/parts/core/common/address"
import ReferentialsService from "~/parts/referentials/referentialsService";
import EService from "~/parts/core/services/eService";
import { type ReferentialInfo } from "~/parts/referentials";
import { InputType } from "~/parts/core";
import {
    type Contribution, type Loan, type Member,
    type Organisation, type OrganisationDetails,
    type OrganisationMember,
    type Refund
} from "~/parts/organisations";
import FileService from "~/parts/medias/fileService";
import { type FileModel } from "~/parts/medias";
import OrganisationsConstantsProvider from "~/parts/organisations/organisationsConstantsProvider";

export function organisationItemText(item: Organisation) {
    const name = `${item.code || ""} ${item.name || ""}`;
    return name.trim() == "" && item.id ? item.id.toString() : name;
}

class OrganisationsService extends EService {

    typeName = OrganisationsConstantsProvider.OrganisationTypeName;
    referentialsService: ReferentialsService;
    fileService: FileService;

    constructor(useApiKey: boolean = false) {
        super(useApiKey);
        this.referentialsService = new ReferentialsService();
        this.fileService = new FileService();
    }

    static getReferentialsInfos(): Array<ReferentialInfo> {
        return [
            {
                typeName: OrganisationsConstantsProvider.OrganisationTypeName,
                metaData: [
                    { text: "#", value: "id", isKey: true },
                    { textKey: "code", value: "code", inputOption: { type: InputType.Text } },
                    { textKey: "name", value: "name", inputOption: { type: InputType.Text } },
                    {
                        textKey: "address", value: "addressId", inputOption: {
                            type: InputType.Dynamic, lookup: {
                                typeName: AddressService.AddressTypeName,
                                valueField: "id",
                                textField: AddressService.addressListItemText
                            },
                            autoFilledOnCreation: "name"
                        }
                    },
                    {
                        textKey: "visual", value: "visual", inputOption: {
                            type: InputType.File, lookup: {
                                valueField: "path"
                            },
                            accept: "image/*"
                        }
                    }
                ],
                helpers: {
                    getSearchablePropertiesValues: (item: Organisation, lookups: { [key: string]: Array<Address> }) => {
                        const searchablePropertiesValues = [item.id, item.code, item.name];
                        const address = lookups.addresses?.find(a => item.addressId == a.id);
                        if (!!address)
                            searchablePropertiesValues.push(...[
                                address.number,
                                address.numberRepeterIndex,
                                address.type,
                                address.name,
                                address.complements,
                                address.city,
                                address.zipCode
                            ]);
                        return searchablePropertiesValues;
                    }
                }
            }
        ];
    }

    async getAllAsync(forceReload = false) {
        const organisations = await this.referentialsService.getAllAsync<Organisation>(this.typeName, forceReload);
        return organisations;
    }

    async getAllMembersAsync(forceReload = false) {
        const members = await this.referentialsService.getAllAsync<Member>(OrganisationsConstantsProvider.MemberTypeName, forceReload);
        return members;
    }

    async saveAsync(organisation: Organisation) {
        const savedOrganisation = await this.referentialsService.saveAsync<Organisation>(this.typeName, organisation);
        return savedOrganisation;
    }

    async addMemberAsync(organisationMember: OrganisationMember) {
        await this.http.post<OrganisationMember, OrganisationMember>(`organisations/addmember`, organisationMember);
    }

    async getOrganisationsMembersAsync(organisationsIds: Array<number> | null = null) {
        const params = !!organisationsIds && !!organisationsIds.length
            ? { params: { organisationsIds } }
            : undefined;
        const members = await this.http.get<Array<OrganisationMember>>(`organisations/organisationsmembers`, params);
        members?.sort((a, b) =>
            parseInt(`${a.memberId}${a.organisationId}`) >= parseInt(`${b.memberId}${b.organisationId}`)
                ? 1 : -1);
        const membersList = members?.map((m, i) => ({ ...m, id: i + 1 } as OrganisationMember));
        return membersList ?? null;
    }

    async removeMemberFromOrganisationAsync(memberOrganisation: OrganisationMember) {
        await this.http.remove<OrganisationMember, OrganisationMember>(`organisations/removemember`, memberOrganisation);
    }

    async getAsync(id: number) {
        const organisation = await this.referentialsService.getAsync<Organisation>(this.typeName, id);
        return organisation;
    }

    async getUserOrganisationsAsync() {
        const organisations = await this.http.get<Array<Organisation>>(`organisations/person/`);
        return organisations;
    }

    async getDetailsAsync(organisationId: number) {
        const organisationDetails = await this.http.get<OrganisationDetails>(`organisations/${organisationId}/details`);
        return organisationDetails;
    }

    async getContributionsAsync(organisationId: number, memberId: number | null) {
        const path = `organisations/${organisationId}/member/${memberId ? memberId + "/" : ""}contributions`;
        const response = await this.http.get<Array<Contribution>>(path);
        const contributions = response?.map(c => {
            c.date = new Date(c.date);
            return c;
        });
        return contributions?.sortBy(c => (c.date as Date).getDate(), "desc") ?? [];
    }

    async getLoansAsync(organisationsId: number, memberId: number | null = null) {
        const path = `organisations/${organisationsId}/member/${!!memberId ? memberId + "/" : ""}loans`;
        const response = await this.http.get<Array<Loan>>(path);
        const loans = response?.map(l => {
            l.date = new Date(l.date);
            l.refunds?.map(r => {
                r.date = new Date(r.date);
                return r;
            });
            const refundAmount = l.refunds?.reduce((p, r) => r.state == 1 ? p + r.amount : p, 0);
            l.refunded = !!l.refunds?.length && !l.refunds?.some(r => r.state == 0) && refundAmount == l.amount;
            l.refunds = l.refunds?.sortBy(r => (r.date as Date).getDate(), "desc");
            return l;
        });
        return loans?.sortBy(l => (l.date as Date).getDate(), "desc") ?? [];
    }

    async addContributionAsync(organisationId: number, contribution: Contribution, memberId: number | null = null) {
        const path = `organisations/${organisationId}/member/${!!memberId ? memberId + "/" : ""}contribution`;
        const response = await this.http.post<Contribution, Contribution>(path, contribution);
        return response;
    }

    async borrowAsync(organisationsId: number, loan: Loan, memberId: number | null = null) {
        const path = `organisations/${organisationsId}/member/${!!memberId ? memberId + "/" : ""}loan`;
        const response = await this.http.post<Loan, Loan>(path, loan);
        return response;
    }

    async refundAsync(loanId: number, payback: Refund) {
        const response = await this.http.post<Refund, Refund>(`organisations/loans/${loanId}/refund`, payback);
        return response;
    }

    async validateContributionAsync(contribId: number) {
        const response = await this.http.put<Contribution>(`organisations/member/contribution/${contribId}/validate`);
        return response;
    }

    async cancelContributionAsync(contribId: number) {
        const response = await this.http.put<Contribution>(`organisations/member/contribution/${contribId}/cancel`);
        return response;
    }

    async validateLoanAsync(loanid: number) {
        const response = await this.http.put<Loan>(`organisations/member/loan/${loanid}/validate`);
        return response;
    }

    async cancelLoanAsync(loanid: number) {
        const response = await this.http.put<Loan>(`organisations/member/loan/${loanid}/cancel`);
        return response;
    }

    async validateRefundAsync(refundId: number) {
        const response = await this.http.put<Refund>(`organisations/member/refund/${refundId}/validate`);
        return response;
    }

    async cancelRefundAsync(refundId: number) {
        const response = await this.http.put<Refund>(`organisations/member/refund/${refundId}/cancel`);
        return response;
    }

    async getMemberByUserIdAsync(organisationId: number, userId: number) {
        const response = await this.http.get<Member>(`organisations/${organisationId}/member/get?userId=${userId}`);
        return response || null;
    }

    async getOrganisationVisualAsync(orga: Organisation) {
        return await this.fileService.get(orga.visualId!);
    }

    async getManyVisualsAsync(orgas: Array<Organisation>) {
        const visualsIds = orgas.reduce((previous: Array<number>, o) => {
            if (!!o.visualId && !previous.includes(o.visualId)) {
                previous.push(o.visualId);
            }
            return previous;
        }, []);
        return await this.fileService.getMany(visualsIds);
    }

    async saveOrganisationVisual(file: File) {
        return this.fileService.uploadFile<FileModel>(file, "organisations/visual");
    }
}

export default OrganisationsService;
